const PlayIconRect = ({
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
}: {
  width?: string;
  height?: string;
  viewBox?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4615 0H1.53846C0.688792 0 0 0.688792 0 1.53846V18.4615C0 19.3112 0.688792 20 1.53846 20H18.4615C19.3112 20 20 19.3112 20 18.4615V1.53846C20 0.688792 19.3112 0 18.4615 0Z"
        fill="#17C964"
      />
      <path
        d="M7.2771 13.4309V6.7847C7.27768 6.6735 7.30764 6.56444 7.36396 6.46855C7.42028 6.37266 7.50095 6.29336 7.59778 6.2387C7.69462 6.18404 7.80418 6.15595 7.91538 6.15727C8.02658 6.15859 8.13544 6.18929 8.23095 6.24624L13.9233 9.55393C14.0191 9.60932 14.0987 9.68893 14.154 9.78481C14.2093 9.88067 14.2385 9.98941 14.2385 10.1001C14.2385 10.2108 14.2093 10.3195 14.154 10.4154C14.0987 10.5113 14.0191 10.5909 13.9233 10.6462L8.23095 13.9693C8.13544 14.0263 8.02658 14.057 7.91538 14.0583C7.80418 14.0596 7.69462 14.0315 7.59778 13.9769C7.50095 13.9222 7.42028 13.8429 7.36396 13.747C7.30764 13.6511 7.27768 13.5421 7.2771 13.4309Z"
        fill="#03041A"
      />
    </svg>
  );
};

export default PlayIconRect;
