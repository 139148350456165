import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  AlbumQueryResult,
  PhotoArtifactTab,
  Story,
} from '../../types.ts/story';
import useInitializeDatoUser from '../common/useInitializeDatoUser';
import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { gptService } from '../../services/ChatGPTService';
import SearchInput from '../sidepanel/SearchInput';
import StoryDashboardContent from './StoryDashboardContent';
import ClipDashboardContent from './ClipDashboardContent';
import { checkWordsInString } from '../../utility/general';
import PrimaryActionButton from '../sidepanel/PrimaryActionButton';
import PlayIconRectOutline from '../../svgs/PlayIconRectOutline';
import AddStoriesModal from './AddStoriesModal';
import NewStoriesQuerySubscriptionComponent, {
  NewStoryUpdate,
} from '../NewStoriesQuerySubscriptionComponent';
import ToastNotification from '../common/ToastNotification';
import { useFlagsCombination } from '../../utility/useFlagsCombination';

type Props = {
  params: {
    [k: string]: string;
  };
};

enum ContentTypes {
  story = 'Stories',
  clip = 'Clips',
}

function buildTargetUrl(
  params: URLSearchParams,
  storyId: string,
  playbackId: string | undefined,
): string {
  // if (params.get('showcase')) {
  //   const data = [] as string[];

  //   params.forEach((value, key) => {
  //     data.push(`${key}=${value}`);
  //   });

  //   return data.length ? '?' + data.join('&') : '';
  // } else {
  //   return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
  //     playbackId || ''
  //   }`;
  // }

  return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
    playbackId || ''
  }`;
}

const Dashboard: FC<Props> = observer((props) => {
  useInitializeDatoUser();
  const album = videoCreator.organization;
  const [stories, setStories] = useState<AlbumQueryResult['stories']>([]);
  const [allStories, setAllStories] = useState<AlbumQueryResult['stories']>([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedTab, setSelectedTab] = useState<ContentTypes>(
    ContentTypes.story,
  );
  const [showModalFor, setShowModalFor] = useState<'add-stories' | null>(null);
  const [bannerHeight, setBannerHeight] = useState<number | undefined>();
  const [newStoryCompletionPercent, setNewStoryCompletionPercent] =
    useState<number>(0);
  const [newStoryIds, setNewStoryIds] = useState<string[]>([]);
  const { enableCreatorStudioNav } = useFlagsCombination();

  useEffect(() => {
    if (!album?.id) return;
    (async () => {
      const stories = (await videoCreator.findManyStories(
        album.id,
      )) as AlbumQueryResult['stories'];
      setAllStories(stories);
      setStories(stories);
    })();
  }, [album]);

  function resetStoryStates(storyId: string) {
    const prevStoryId = urlSearchParams.get('storyId');
    if (prevStoryId !== storyId) {
      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.story,
        resource: undefined,
      };
      videoCreator.talkingPointContent = null;
      if (gptService.talkingPointController) {
        gptService.talkingPointController.abort();
      }
    }
  }

  const getDescription = (story: AlbumQueryResult['stories'][0]) => {
    const descriptionResponse = story?.aiResponse?.responses?.find(
      (response) => response.title === 'Description',
    );
    return descriptionResponse?.response?.toString() || '';
  };

  const handleSearchContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();
    const stories = allStories.filter((s) => {
      const summary = getDescription(s);
      return (
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value) ||
        checkWordsInString(summary, value)
      );
    });
    setStories(stories);
  };

  const handleSearchClipContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();

    const newStories = [];
    for (let s of allStories) {
      const isInStory =
        checkWordsInString(s.storyTeller.name, value) ||
        checkWordsInString(s.title, value);
      if (isInStory) {
        newStories.push(s);
        continue;
      }
      const clips = s.otherVideos.filter((v) => {
        return checkWordsInString(v.title, value);
      });
      newStories.push({ ...s, otherVideos: clips });
    }
    setStories(newStories);
  };

  const handleNewStoryUpdateReceived = (storyUpdates: NewStoryUpdate[]) => {
    const newAllStories: AlbumQueryResult['stories'] = [];
    allStories.forEach((item) => {
      const story = storyUpdates.find((u) => u.id === item.id);
      if (story) {
        if (
          story.originalVideo?.video?.thumbnailUrl &&
          story.transcription?.jobStatus === 'COMPLETED'
        ) {
          setNewStoryCompletionPercent(100);
          videoCreator.toastState = {
            state: 'success',
            message: 'Story successfully uploaded',
          };
        } else if (
          story.originalVideo?.video?.thumbnailUrl ||
          story.transcription?.jobStatus === 'COMPLETED'
        ) {
          setNewStoryCompletionPercent(75);
        } else if (story.transcription?.jobStatus === 'FAILED') {
          setNewStoryCompletionPercent(100);
          videoCreator.toastState = {
            state: 'error',
            message: 'Transcription generation failed for new story',
          };
        }
        newAllStories.push({ ...item, ...story });
      } else {
        newAllStories.push(item);
      }
    });
    setAllStories(newAllStories);
    setStories(
      stories.map((item) => {
        const story = storyUpdates.find((u) => u.id === item.id);
        if (story) {
          return { ...item, ...story };
        } else {
          return item;
        }
      }),
    );
  };

  const handleNewStoryInitialized = (story: Story) => {
    const newStory = story as unknown as AlbumQueryResult['stories'][0];
    setNewStoryCompletionPercent(25);
    setAllStories([newStory, ...allStories]);
    setStories([newStory, ...stories]);
    setNewStoryIds(newStoryIds.concat(newStory.id));
  };

  const handleNewStoryCreated = (story: Story) => {
    const newStory = story as unknown as AlbumQueryResult['stories'][0];
    if (newStoryCompletionPercent < 50) {
      setNewStoryCompletionPercent(50);
    }
    setAllStories(
      allStories.map((item) => (item.id === newStory.id ? newStory : item)),
    );
    setStories(
      stories.map((item) => (item.id === newStory.id ? newStory : item)),
    );
  };

  const isStoryLoading = (s: AlbumQueryResult['stories'][0]): boolean => {
    return (
      newStoryIds.includes(s.id) &&
      (!s.originalVideo?.video?.thumbnailUrl ||
        !['FAILED', 'COMPLETED'].includes(s.transcription?.jobStatus))
    );
  };

  return (
    <Main bannerHeight={bannerHeight}>
      <TopContent>
        <Title>Story Manager</Title>
        <Tabs>
          {Object.entries(ContentTypes).map(([k, v]) => (
            <Tab
              onClick={() => setSelectedTab(v)}
              isSelected={selectedTab === v}
            >
              {v}
            </Tab>
          ))}
        </Tabs>
        <SearchInput
          iconRight={true}
          placeholder={
            selectedTab === ContentTypes.story
              ? 'Search your stories'
              : 'Search your clips'
          }
          radius="10px"
          width="300px"
          handleAction={(text) => {
            if (selectedTab === ContentTypes.story) {
              handleSearchContent(text);
            } else if (selectedTab === ContentTypes.clip) {
              handleSearchClipContent(text);
            }
          }}
          autoSubmitDelay={500}
        />
        {selectedTab === ContentTypes.story && enableCreatorStudioNav && (
          <>
            <AddStoriesButton
              onClick={() => setShowModalFor('add-stories')}
              isActivated
            >
              <PlayIconRectOutline />
              Add Stories
            </AddStoriesButton>
          </>
        )}
      </TopContent>

      <Table>
        {(() => {
          switch (selectedTab) {
            case ContentTypes.story:
              return (
                <StoryDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                  isStoryLoading={isStoryLoading}
                />
              );

            case ContentTypes.clip:
              return (
                <ClipDashboardContent
                  stories={stories}
                  resetStoryStates={resetStoryStates}
                  buildTargetUrl={buildTargetUrl}
                />
              );
          }
        })()}
      </Table>
      {showModalFor === 'add-stories' && (
        <AddStoriesModal
          closeModal={() => setShowModalFor(null)}
          onStoryInitialized={handleNewStoryInitialized}
          onStoryCreated={handleNewStoryCreated}
        />
      )}
      {newStoryCompletionPercent > 0 && newStoryCompletionPercent < 100 && (
        <Banner
          ref={(ref) => {
            setBannerHeight(ref?.getBoundingClientRect()?.height);
          }}
        >
          <BannerText>We’re uploading your video...</BannerText>
          <ProgressBarOutline>
            <ProgressBar
              width={`${(newStoryCompletionPercent / 100) * 404}px`}
            />
          </ProgressBarOutline>
          <ProgressPercent>{newStoryCompletionPercent}%</ProgressPercent>
        </Banner>
      )}
      {!!newStoryIds.length && (
        <NewStoriesQuerySubscriptionComponent
          key={newStoryIds.join('_')}
          storyIds={newStoryIds}
          onDataReceived={handleNewStoryUpdateReceived}
          onError={() => {
            videoCreator.toastState = {
              state: 'error',
              message: 'Failed to update new story',
            };
          }}
        />
      )}
      {videoCreator.toastState && <ToastNotification />}
    </Main>
  );
});

export default Dashboard;

const Main = styled.div<{ bannerHeight?: number }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  padding-bottom: ${(props) => `${(props.bannerHeight || 0) + 20}`}px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  min-width: 440px;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  color: #f3e9d7;
  font-weight: 700;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Tabs = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-left: 50px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3e9d7;
  cursor: pointer;
  &:hover {
    color: #f2d093;
    text-decoration: underline;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      text-decoration: underline;
    `}
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: 'Inter', sans-serif;
`;

const AddStoriesButton = styled(PrimaryActionButton)`
  width: 240px;
  max-width: 240px;
  margin-left: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;

const Banner = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 24px;
  z-index: 999;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 14px 8px 16px 0px rgba(0, 0, 0, 0.4);
`;

const BannerText = styled.div`
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ProgressBarOutline = styled.div`
  width: 404px;
  height: 4px;
  background: #484848;
  border-radius: 4px;
  margin: 24px auto 8px;
`;

const ProgressBar = styled.div<{ width: string }>`
  background: #17c964;
  height: 4px;
  width: ${(props) => props.width};
  border-radius: 4px;
`;

const ProgressPercent = styled.div`
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
`;
