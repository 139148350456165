import styled from 'styled-components';
import Modal from '../common/Modal';
import PrimaryActionButton from '../sidepanel/PrimaryActionButton';
import DragAndDrop from '../common/DragAndDrop';
import { Story } from '../../types.ts/story';
import { useStoryCreator, Step } from './useStoryCreator';

type StoryTypeOption = {
  value: 'produced' | 'raw';
  text: string;
};

const STORY_TYPE_OPTIONS: StoryTypeOption[] = [
  {
    value: 'produced',
    text: 'I have produced content',
  },
  {
    value: 'raw',
    text: 'I have raw content',
  },
];

const AddStoriesModal = ({
  closeModal,
  onStoryInitialized,
  onStoryCreated,
}: {
  closeModal: () => void;
  onStoryInitialized: (s: Story) => void;
  onStoryCreated: (s: Story) => void;
}) => {
  const {
    step,
    goToNextStep,
    canGoToStep,
    handleStoryVideoUpload,
    handleStoryBRollUpload,
    storyName,
    setStoryName,
    storyType,
    setStoryType,
    storyTellerName,
    setStoryTellerName,
  } = useStoryCreator({
    onStoryInitialized,
    onStoryCreated: (...args) => {
      onStoryCreated(...args);
      closeModal();
    },
  });

  const renderStep = () => {
    switch (step) {
      case Step.one:
        return (
          <StepOne
            goToNextStep={goToNextStep}
            canGoToStep={canGoToStep(Step.two)}
            handleStoryVideoUpload={handleStoryVideoUpload}
          />
        );
      case Step.two:
        return (
          <StepTwo
            goToNextStep={goToNextStep}
            canGoToStep={canGoToStep(Step.three)}
            storyName={storyName}
            setStoryName={setStoryName}
            storyType={storyType}
            setStoryType={setStoryType}
            storyTellerName={storyTellerName}
            setStoryTellerName={setStoryTellerName}
          />
        );

      case Step.three:
        return (
          <StepThree
            goToNextStep={goToNextStep}
            handleStoryBRollUpload={handleStoryBRollUpload}
          />
        );
      default:
        return null;
    }
  };

  if (step === Step.four) {
    return null;
  }

  return (
    <Modal isOpen closeModal={closeModal}>
      <Wrapper>
        <Heading>
          Story producer (Step {step} of {Step.four - 1})
        </Heading>
        {renderStep()}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  width: 440px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: content-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  text-align: center;
  color: #f3e9d7;
  margin: 0;

  margin-bottom: 24px;
`;

const ProceedButton = styled(PrimaryActionButton)`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;

const UploadArea = styled.div`
  margin-bottom: 16px;
`;

const Hint = styled.span`
  font-size: 8px;
`;

const FieldSet = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: #03041a;
`;

const StoryTypeList = styled.div`
  display: flex;
  gap: 8px;
`;

const StoryTypeItem = styled.div.attrs(
  (props: { isCurrent: boolean }) => props,
)`
  padding: 16px;
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  color: #f3e9d7;
  flex: 1;
  border: 1px solid #848484;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  font-family: Inter;
  line-height: 18px;
  user-select: none;
  ${(props) =>
    props.isCurrent &&
    `
    color: #F2D093;
    border-color: #F2D093;
  `}

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #d7d7e1;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.isCurrent &&
      `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
      border-color: #F2D093;
    `}
  }
`;

export default AddStoriesModal;

function StepOne({
  goToNextStep,
  canGoToStep,
  handleStoryVideoUpload,
}: {
  goToNextStep: () => void;
  canGoToStep: boolean;
  handleStoryVideoUpload: (f: File[]) => void;
}) {
  return (
    <>
      <Title>Upload your video</Title>
      <UploadArea>
        <DragAndDrop
          width={440}
          callback={handleStoryVideoUpload}
          acceptType="video"
          multiple={false}
          description={
            <>
              Or upload (or drag and drop) your video here.
              <br />
              <Hint>(Max file size is 1 GB)</Hint>
            </>
          }
        />
      </UploadArea>
      <ProceedButton onClick={goToNextStep} disabled={!canGoToStep} isActivated>
        Next
      </ProceedButton>
    </>
  );
}

function StepTwo({
  goToNextStep,
  canGoToStep,
  storyName,
  setStoryName,
  storyType,
  setStoryType,
  storyTellerName,
  setStoryTellerName,
}: {
  goToNextStep: () => void;
  canGoToStep: boolean;
  storyName: string;
  setStoryName: (s: string) => void;
  storyType: 'raw' | 'produced';
  setStoryType: (t: 'raw' | 'produced') => void;
  storyTellerName: string;
  setStoryTellerName: (n: string) => void;
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (canGoToStep) {
          goToNextStep();
        }
      }}
    >
      <Title>Story Details</Title>
      <StoryTypeList>
        {STORY_TYPE_OPTIONS.map((o) => (
          <StoryTypeItem
            isCurrent={o.value === storyType}
            onClick={() => setStoryType(o.value)}
            key={o.value}
          >
            {o.text}
          </StoryTypeItem>
        ))}
      </StoryTypeList>
      <FieldSet>
        <FieldName>Story Name</FieldName>
        <Input
          type="storyName"
          required
          placeholder="Name your story"
          value={storyName}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setStoryName(e.target.value)}
        />
      </FieldSet>
      <FieldSet>
        <FieldName>Storyteller Name</FieldName>
        <Input
          type="storyTellerName"
          required
          placeholder="Who is the storyteller"
          value={storyTellerName}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setStoryTellerName(e.target.value)}
        />
      </FieldSet>
      <ProceedButton isActivated disabled={!canGoToStep} type="submit">
        Next
      </ProceedButton>
    </form>
  );
}

function StepThree({
  goToNextStep,
  handleStoryBRollUpload,
}: {
  goToNextStep: () => void;
  handleStoryBRollUpload: (f: File[]) => void;
}) {
  return (
    <>
      <Title>Lets collect BRoll</Title>
      <UploadArea>
        <DragAndDrop
          width={440}
          callback={handleStoryBRollUpload}
          acceptType="all"
          multiple
          description={
            <>
              Or upload (or drag and drop) your videos and photos here.
              <br />
              <Hint>(Max file size is 100 MB per file)</Hint>
            </>
          }
        />
      </UploadArea>
      <ProceedButton onClick={goToNextStep} isActivated>
        Next
      </ProceedButton>
    </>
  );
}
