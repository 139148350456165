import { useEffect, useState } from 'react';
import { Story, StoryVideo } from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';

export enum Step {
  one = 1,
  two,
  three,
  four,
}

type ReturnType = {
  step: Step;
  goToNextStep: () => void;
  canGoToStep: (s: number) => boolean;
  handleStoryVideoUpload: (f: File[]) => void;
  handleStoryBRollUpload: (f: File[]) => void;
  storyName: string;
  setStoryName: (s: string) => void;
  storyType: 'raw' | 'produced';
  setStoryType: (t: 'raw' | 'produced') => void;
  storyTellerName: string;
  setStoryTellerName: (n: string) => void;
};

type Params = {
  onStoryInitialized: (s: Story) => void;
  onStoryCreated: (s: Story) => void;
};

export function useStoryCreator(params: Params): ReturnType {
  const [step, setStep] = useState<Step>(1);
  const [story, setStory] = useState<Story>();

  // step 1 data
  const [storyVideoFile, setStoryVideoFile] = useState<File | null>(null);
  const [storyVideoUpload, setStoryVideoUpload] =
    useState<SimpleSchemaTypes.Upload | null>(null);
  const [isStoryVideoUploading, setIsStoryVideoUploading] =
    useState<boolean>(false);

  // step 2 data
  const [storyName, setStoryName] = useState<string>('');
  const [storyTellerName, setStoryTellerName] = useState<string>('');
  const [storyType, setStoryType] = useState<'raw' | 'produced'>('raw');

  // step 3 data
  const [storyArtifactsFiles, setStoryArtifactsFiles] = useState<File[]>([]);
  const [storyArtifacts, setStoryArtifacts] = useState<
    SimpleSchemaTypes.Upload[]
  >([]);
  const [storyArtifactsVideoFiles, setStoryArtifactsVideoFiles] = useState<
    File[]
  >([]);
  const [storyArtifactsVideo, setStoryArtifactsVideo] = useState<
    SimpleSchemaTypes.Upload[]
  >([]);
  const [bRollReady, setBRollReady] = useState<boolean>(false);

  useEffect(() => {
    if (
      story &&
      !story?.originalVideo &&
      !isStoryVideoUploading &&
      storyVideoUpload
    ) {
      (async () => {
        await videoCreator.updateStory({ id: story.id }, storyVideoUpload.id);
      })();
    }
  }, [isStoryVideoUploading, storyVideoUpload, story]);

  useEffect(() => {
    if (step === Step.three && !story) {
      handleStoryCreate();
    }
    if (step === Step.four && story?.id) {
      params.onStoryInitialized(story);
    }
  }, [step, story?.id]);

  useEffect(() => {
    if (step === Step.four && story && storyVideoUpload && bRollReady) {
      params.onStoryCreated(story);
    }
  }, [step, bRollReady, story, storyVideoUpload]);

  useEffect(() => {
    if (
      storyArtifacts.length === storyArtifactsFiles.length &&
      storyArtifactsVideo.length === storyArtifactsVideoFiles.length
    ) {
      (async () => {
        if (
          (storyArtifactsFiles.length === 0 &&
            storyArtifactsVideoFiles.length === 0 &&
            step !== Step.four) ||
          !story
        ) {
          return;
        }
        const storyUpdate = await videoCreator.updateStory({
          id: story.id,
          storyArtifacts: storyArtifacts.map((a) => ({
            id: a.id,
          })),
          storyArtifactsVideo: storyArtifactsVideo.map((a) => ({
            id: a.id,
          })),
        });
        setStory({
          ...story,
          ...storyUpdate,
        });
        setBRollReady(true);
      })();
    }
  }, [
    storyArtifacts,
    storyArtifactsFiles,
    storyArtifactsVideo,
    storyArtifactsVideoFiles,
    step,
    story?.id,
  ]);

  const handleStoryCreate = async () => {
    setStory(
      await videoCreator.createStory(
        {
          title: storyName,
          storyType: storyType,
          storyTeller: {
            name: storyTellerName,
            id: (await videoCreator.findOrCreateStoryTeller(storyTellerName))
              .id,
          },
          primaryShowcase: videoCreator.organization,
          byExternalUser: videoCreator.currentUserType === 'external',
        },
        storyVideoUpload?.id,
      ),
    );
  };

  const handleStoryVideoUpload = async (files: File[]) => {
    if (!files.length) {
      console.error('No files at AddStoriesModal handleStoryVideoUpload');
      return;
    }
    setStoryVideoFile(files[0]);
    goToNextStep();

    try {
      setIsStoryVideoUploading(true);
      const videoUpload =
        await videoCreator.datoClient?.uploads.createFromFileOrBlob({
          fileOrBlob: files[0],
          filename: files[0].name,
        });
      setStoryVideoUpload(videoUpload || null);
    } catch (err) {
      setStoryVideoUpload(null);
      console.error('Story video upload failed', err);
    } finally {
      setIsStoryVideoUploading(false);
    }
  };

  const handleStoryBRollUpload = async (files: File[]) => {
    const imageFiles = files.filter((f) => f.type.includes('image'));
    const videoFiles = files.filter((f) => f.type.includes('video'));

    setStoryArtifactsFiles(storyArtifactsFiles.concat(imageFiles));
    setStoryArtifactsVideoFiles(storyArtifactsVideoFiles.concat(videoFiles));

    imageFiles.forEach((file) => {
      videoCreator.datoClient?.uploads
        .createFromFileOrBlob({
          fileOrBlob: file,
          filename: file.name,
        })
        .then((u) => {
          setStoryArtifacts(storyArtifacts.concat(u));
        });
    });

    videoFiles.forEach((file) => {
      videoCreator.datoClient?.uploads
        .createFromFileOrBlob({
          fileOrBlob: file,
          filename: file.name,
        })
        .then((u) => {
          setStoryArtifactsVideo(storyArtifactsVideo.concat(u));
        });
    });
  };

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const canGoToStep = (nextStep: number): boolean => {
    if (nextStep === Step.two) {
      return !!storyVideoFile;
    }
    if (nextStep === Step.three) {
      return !!storyName && !!(storyTellerName || '').trim();
    }
    if (nextStep === Step.four) {
      return !!story;
    }
    return true;
  };

  return {
    step,
    goToNextStep,
    canGoToStep,
    handleStoryVideoUpload,
    handleStoryBRollUpload,
    storyName,
    setStoryName,
    storyType,
    setStoryType,
    storyTellerName,
    setStoryTellerName,
  };
}
