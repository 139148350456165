import { RequestDocument } from 'graphql-request';

const VIDEO_FILE_GQL_FRAGMENT = `
{
width
height
url
video {
  duration
  thumbnailUrl
  muxPlaybackId
}
customData
}
`;

const REFERENCING_SHARED_CONTENT = `
{
id
socialProfileGroup {
  id
}
video {
  id
  title
}
shareableContent {
  id
  title
}
story {
  id
}
organization {
  id
}
external
postId
postUrl
lastFetched
postType
platform
post
title
likes
comments
shares
clicks
views
}
`;

const REFERENCING_CAPTION = `
{
id
platform
caption
story{
  id
}
shareable{
  __typename
}
}`;

const MEDIA_QUERY = `{
id
title
customData
format
mimeType
url
width
height
responsiveImage {
  base64
  sizes
  src
  srcSet
  alt
  title
}
video {
  duration
  muxPlaybackId
  thumbnailUrl
  mp4UrlHigh: mp4Url(exactRes: high, res: high)
  mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
  mp4UrlLow: mp4Url(exactRes: low, res: low)
  mp4Url
}
_createdAt
}`;

const TEXT_BRANDING_QUERY = `
        id
        title
        fontFamily
        fontSize
        aspectRatio
        fillColor {
          cssRgb
          red
          green
          blue
          alpha
          hex
        }
        backgroundColor {
          cssRgb
          red
          green
          blue
          alpha
          hex
        }
        backgroundTransparency
        fontWeight
        uppercase
        italic
        xAlignment
    `;
const BASIC_TEXT_BRANDING_QUERY = `{
  ${TEXT_BRANDING_QUERY}
  enterAnimation
  enterAnimationLength
  exitAnimation
  exitAnimationLength
  length
}`;

const KARAOKE_TEXT_BRANDING_QUERY = `{
  ${TEXT_BRANDING_QUERY}
  xPos
  yPos
  strokeColor {
    cssRgb
    red
    green
    blue
    alpha
    hex
  }
  strokeWidth
  animation
  instagramLines
  hideComma
  hidePeriod
  hideFillers
}`;

export const SHOWCASE_QUERY: RequestDocument = `query getShowcase($slug: String!) {
showcase(filter: {slug: {eq: $slug}}) {
  id
  title
  organization
  slug
  logo {
    url
  }
  mainImage {
    url
  }
  stories {
    id
    originalVideo {
      url
    }
  }
  organizationArtifacts {
    id
    title
    customData
    format
    mimeType
    url
    width
    height
    responsiveImage {
      base64
      sizes
      srcSet
      alt
      title
    }
    video {
      duration
      muxPlaybackId
      thumbnailUrl
      mp4Url
      mp4UrlHigh: mp4Url(exactRes: high, res: high)
      mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
      mp4UrlLow: mp4Url(exactRes: low, res: low)
    }
    _createdAt
  }

  organizationLogos {
    id
    title
    customData
    format
    mimeType
    url
    width
    height
    responsiveImage {
      base64
      sizes
      srcSet
      alt
      title
    }
    video {
      duration
      muxPlaybackId
      thumbnailUrl
      mp4Url
      mp4UrlHigh: mp4Url(exactRes: high, res: high)
      mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
      mp4UrlLow: mp4Url(exactRes: low, res: low)
    }
    _createdAt
  }

  organizationArtifactsVideo {
    id
    title
    customData
    format
    mimeType
    url
    video {
      duration
      muxPlaybackId
      thumbnailUrl
      mp4UrlHigh: mp4Url(exactRes: high, res: high)
      mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
      mp4UrlLow: mp4Url(exactRes: low, res: low)
      mp4Url
    }
    _createdAt
  }
  lowerThirdTextBranding ${BASIC_TEXT_BRANDING_QUERY}
  karaokeTextBranding ${KARAOKE_TEXT_BRANDING_QUERY}
  lastUsedTemplates
}
}`;

export const ALBUM_QUERY: RequestDocument = `query getAlbum($id: ItemId) {
showcase(filter: {id: {eq: $id}}) {
      id
      title
      stories {
        id
        title
        aiResponse
        byExternalUser
        transcription {
          jobStatus
        }
        storyTeller {
          name
        }
        _allReferencingSharedContents
        ${REFERENCING_SHARED_CONTENT}
        _publishedAt
        thumbnail {
          url
        }
        originalVideo {
          video {
            duration
            thumbnailUrl
            muxPlaybackId
          }
        }
        finalVideo {
          thumbnail {
            url
          }
          videoFilePrimary
          ${VIDEO_FILE_GQL_FRAGMENT}
        }
        otherVideos {
          id
          title
          videoStatus
          sourcePlatform
          isClientReady
          isHidden
          clipJson
          lastActionJson
          videoJson
          thumbnail {
            url
          }
          shareableImageId
          videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
          slug
          hash
          aspectRatio
          aiGeneratedContent {
            id
            prompt
            generatedContent
          }
          associatedVideos {
            id
            aspectRatio
            videoStatus
            sourcePlatform
            isClientReady
            isHidden
            clipJson
            lastActionJson
            thumbnail {
              url
            }
            shareableImageId
            videoFilePrimary
              ${VIDEO_FILE_GQL_FRAGMENT}
          }
          _allReferencingSharedContents
          ${REFERENCING_SHARED_CONTENT}
          _allReferencingCaptions ${REFERENCING_CAPTION}
          _publishedAt
        }
      }
      organizationArtifacts ${MEDIA_QUERY}
      organizationArtifactsVideo ${MEDIA_QUERY}
      organizationLogos ${MEDIA_QUERY}
      contributors {
        id
        name
        email
      }
      logo {
        url
      }
      mainImage {
        url
      }

      lowerThirdTextBranding ${BASIC_TEXT_BRANDING_QUERY}
      karaokeTextBranding ${KARAOKE_TEXT_BRANDING_QUERY}
      lastUsedTemplates
  }
}`;

export const ALBUM_STORY_IDS_QUERY: RequestDocument = `query getAlbum($id: ItemId) {
  showcase(filter: {id: {eq: $id}}) {
    stories {
      id
    }
  }
}`;

export const STORY_QUERY: RequestDocument = `query getStory($id: ItemId) {
    story(filter: {id: {eq: $id}}) {
        id
        slug
        hash
        title(locale: en)
        description(locale: en)
        brollPage
        contributors {
          id
          name
          email
        }
        primaryShowcase {
          id
          title
          organization
          slug
          logo {
            url
          }
          mainImage {
            url
          }
          organizationArtifacts ${MEDIA_QUERY}
          organizationArtifactsVideo ${MEDIA_QUERY}
          organizationLogos ${MEDIA_QUERY}
        }
        _allReferencingShowcases{
          id
          title
          organization
          slug
          logo {
            url
          }
          mainImage {
            url
          }
          profiles {
            id
            name
            platforms
          }
          organizationArtifacts ${MEDIA_QUERY}
          organizationArtifactsVideo ${MEDIA_QUERY}

          lowerThirdTextBranding ${BASIC_TEXT_BRANDING_QUERY}
          karaokeTextBranding ${KARAOKE_TEXT_BRANDING_QUERY}
          lastUsedTemplates
          organizationLogos ${MEDIA_QUERY}
        }
        thumbnail {
          url
        }
        aiGeneratedContent{
          id
          prompt
          generatedContent
        }
        _allReferencingSharedContents
        ${REFERENCING_SHARED_CONTENT}

        _allReferencingCaptions ${REFERENCING_CAPTION}

        alreadyGeneratedContent
        aiResponse
        byExternalUser
        savedBlog
        savedTalkingPointContent
        savedEmail
        shareableImages {
          id
          imagefile {
            id
            title
            url
            width
            height
            responsiveImage {
              srcSet
              alt
              title
            }
            _createdAt
          }
          quote
          storytellerName
          _allReferencingSharedContents
          ${REFERENCING_SHARED_CONTENT}

          _allReferencingCaptions ${REFERENCING_CAPTION}
        }
        transcription {
          jobStatus
          audio {
            url
            format
            mimeType
            customData
          }
          elementsJson {
            id
            url
          }
          waveformData {
            id
            url
          }
        }
        originalVideo {
          id
          url
          height
          width
          video {
            duration
            thumbnailUrl
            muxPlaybackId
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
            mp4Url
          }
          customData
        }
        previousOriginalVideo {
          url
          height
          width
          video {
            duration
            thumbnailUrl
            muxPlaybackId
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
            mp4Url
          }
          customData
        }
        finalVideo {
          id
          title
          thumbnail {
            url
          }
          videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
          videoStatus
          sourcePlatform
        }
        otherVideos {
          id
          title
          videoStatus
          sourcePlatform
          isClientReady
          isHidden
          clipJson
          lastActionJson
          thumbnail {
            url
          }
          shareableImageId
          videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
          slug
          hash
          aspectRatio
          videoJson
          aiGeneratedContent {
            id
            prompt
            generatedContent
          }
          associatedVideos {
            id
            aspectRatio
            videoStatus
            sourcePlatform
            isClientReady
            isHidden
            clipJson
            lastActionJson
            videoJson
            thumbnail {
              url
            }
            shareableImageId
            videoFilePrimary
              ${VIDEO_FILE_GQL_FRAGMENT}
          }
          _allReferencingSharedContents
          ${REFERENCING_SHARED_CONTENT}
          _allReferencingCaptions ${REFERENCING_CAPTION}

        }
        tags {
          name
        }
        storyTeller {
          name
        }
        storyArtifacts ${MEDIA_QUERY}
        storyAssets ${MEDIA_QUERY}
        aiPhotos ${MEDIA_QUERY}
        storyArtifactsVideo ${MEDIA_QUERY}
        myAudios {
          id
          title
          duration
          metadataStatus
          waveformUploadId
          song {
           id
           url
           title
           tags
           customData
          }
        }
  	}
  }`;

export const ALL_VIDEO_SOURCES_IN_STORY_QUERY: RequestDocument = `query getStory($id: ItemId) {
  story(filter: {id: {eq: $id}}) {
    originalVideo {
      url
      height
      width
      video {
        duration
        thumbnailUrl
        muxPlaybackId
        mp4UrlHigh: mp4Url(res: high)
        mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
        mp4UrlLow: mp4Url(exactRes: low, res: low)
        mp4Url
      }
      customData
    }
    previousOriginalVideo {
      url
      height
      width
      video {
        duration
        thumbnailUrl
        muxPlaybackId
        mp4UrlHigh: mp4Url(res: high)
        mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
        mp4UrlLow: mp4Url(exactRes: low, res: low)
        mp4Url
      }
      customData
    }
    otherVideos {
      id
      clipJson
      lastActionJson
      videoJson
      associatedVideos {
        id
        clipJson
        lastActionJson
        videoJson
      }
    }
  }
}`;

export const SHARABLE_SHARED_CONTENT_QUERY: RequestDocument = `query getSharedContents($id: String) {
  allSharedContents(filter: {sharableContent: {eq: $id}}) _allReferencingSharedContents ${REFERENCING_SHARED_CONTENT}
}`;

export const AIPROMPT_QUERY: RequestDocument = `query {
  allAiPrompts{
    id
    promptFields{
      id
      name
      description
    }
    functionDescription,
    description
    title
    followUp
  }
}`;

export const AIPROMPT_SINGLE_BY_TITLE_QUERY: RequestDocument = `query getAipromptSingle($title: String!){
  aiPromptSingle(filter: {title: {matches: {pattern: $title, caseSensitive:false} }}){
    id
    promptFields{
      id
      name
      description
    }
    description
    title
    followUp
  }
} `;

export const STOCK_MUSIC_QUERY: RequestDocument = `query {
  allMusics{
    id
    genre
    collection {
      id
      url
      title
      tags
      customData
    }
  }
}`;

export const MY_AUDIO_QUERY: RequestDocument = `query getMyAudios($ids: [ItemId]){
  allMyAudios(filter: {id: {in: $ids}}){
    id
    title
    duration
    metadataStatus
    waveformUploadId
    song {
      id
      url
      title
      tags
      customData
    }
  }
}`;

export const VIDEOS_RENDERING_STATUS_QUERY: RequestDocument = `query getVideos($ids: [ItemId]) {
    allVideos(filter: {id: {in: $ids}}) {
      id
      renderId
      videoStatus
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      isHidden
      aspectRatio
      lastActionJson
    }
  }`;

export const NEW_STORIES_COMPLETION_QUERY: RequestDocument = `query getStories($ids: [ItemId]) {
    allStories(filter: {id: {in: $ids}}) {
        id,
        originalVideo {
          video {
            duration
            thumbnailUrl
            muxPlaybackId
          }
        }
        transcription {
          jobStatus
        }
    }
}`;

export const ORIGINAL_VIDEO_TRANSCRIPTION_QUERY: RequestDocument = `query getStory($id: ItemId) {
    story(filter: {id: {eq: $id}}) {
      id
      transcription {
        jobStatus
        elementsJson {
          id
          url
        }
      }
  	}
  }`;

export const VIDEO_FILES_QUERY: RequestDocument = `query getVideoFilesFormatted($id: ItemId) {
  video(filter: {id: {eq: $id}}) {
    id
    title
    videoJson
    associatedVideos {
      id
      aspectRatio
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
    }
    _allReferencingVideos(through: {fields: {anyIn: video_associatedVideos}}) {
      id
      title
      aspectRatio
      associatedVideos {
        id
        aspectRatio
      }
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      clipJson
      videoJson
    }
    _allReferencingSharedContents ${REFERENCING_SHARED_CONTENT}
    _allReferencingCaptions ${REFERENCING_CAPTION}
    thumbnail {
      id
      url
      responsiveImage {
        src
        srcSet
      }
    }
    videoFilePrimary
      ${VIDEO_FILE_GQL_FRAGMENT}
    lastActionJson
  }
}`;

export const AI_FLOW_QUERY: RequestDocument = `query getAiFlow($title: String!) {
  aiFlow(filter: {title: {eq: $title}}) {
    id
    title
    steps {
      label
      systemMessageTemplate
      userPromptTemplate
      overrideMessagesFromStep
      maxAttempts
      iterateOver
      modelName
      temperature
      temperatureFunction
      retryConditionFunction
      filterFunction
      reducerFunction
    }
    resultMapperFunction
    logEnabled
  }
}`;

export const PERSON_BY_EMAIL_QUERY: RequestDocument = `query getPersonByEmail($email: String!) {
  allPeople(filter: {email: {eq: $email}}) {
    id
    name
  }
}`;

export const PERSON_BY_NAME_QUERY: RequestDocument = `query getPersonByName($name: String!) {
  allPeople(filter: {name: {matches: {pattern: $name}}}) {
    id
  }
}`;
