import {
  TranscriptChange,
  TranscriptElement,
} from '../videoTranscriptionProcessor/utils';
import { videoCreator } from '../stores/VideoCreatorStore';
import { StoryVideo, Video } from '../types.ts/story';
import { loadAIFlow } from '../aiFlow/AIFlowLoader';
import { AIFlowStep } from '../aiFlow/AIFlowStep';
import { toJS } from 'mobx';

export type ClipFragment = {
  index: number;
  text: string;
  duration: number;
  startTime: number;
  muxPlaybackId: string;
  platform: string;
  theme: string;
  transcriptPosition: {
    startIndex: number;
    endIndex: number;
  };
};

// export const defaultSystemMessage =
//   'You get a story as an input and you return multiple short parts of the original story of specified length which sound most better according to a desired theme or mood.';
// export const defaultUserPrompt =
//   'Find the most emotional parts of the following story about love, friendship and relationship. Use exactly the same words from original text. I want the parts to contain approximately {{newVideoWords}} words. Use newlines as a separator between parts. ';

// const defaultModel = 'gpt-3.5-turbo-16k';

// const clipsFunctionCall = [
//   {
//     name: 'return_results_as_array_of_story_parts',
//     description:
//       'Return segments of the original story requested by user as an array, each element of which contains a single part of length and theme specified by user',
//     parameters: {
//       type: 'object',
//       properties: {
//         story_parts: {
//           type: 'array',
//           description:
//             'Array of the most emotional parts of the original story requested by user',
//           items: {
//             type: 'string',
//             description:
//               'The story part which fits user specified length and theme',
//           },
//         },
//       },
//     },
//   },
// ];

export const SUPPORTED_PRESETS = [
  'Inspirational',
  'Emotional Moment',
  'Community Impact',
  'Short Story',
  'Salient Quote',
  'Call to Action',
];

const getFlowNameByTheme = (theme: string) => {
  if (SUPPORTED_PRESETS.includes(theme)) return `Clip Producer - ${theme}`;
  return 'Clip Producer - Custom';
};

export const getDurationBySocialPlatform = (platform: string) => {
  switch (platform) {
    case 'tiktok':
      return [5, 180];
    case 'instagram':
      return [20, 70];
    case 'youtube':
      return [90, 420];
    case 'facebook':
      return [20, 70];
    case 'twitter':
      return [10, 120];
    case 'linkedin':
      return [10, 120];
    default:
      return [20, 60];
  }
};

const getRangeForDuration = (duration: number) => {
  return (
    {
      15: [8, 22],
      30: [22, 42],
      60: [42, 75],
      90: [75, 108],
      120: [108, 140],
    }[duration] || [0.7 * duration, 1.3 * duration]
  );
};

export const produceClips = async (params: {
  transcriptionText: string;
  originalVideo: StoryVideo;
  originalDuration: number;
  transcriptionElements: TranscriptElement[];
  duration: number | null;
  theme: string;
  platform: string;
  onClipsProduced?: (clips: ClipFragment[]) => void;
  abortToken: { id: string; aborted: boolean };
}) => {
  const {
    transcriptionText,
    transcriptionElements,
    duration,
    originalDuration,
    theme,
    platform,
    originalVideo,
    onClipsProduced,
    abortToken,
  } = params;

  // debugger;
  let [durationMin, durationMax] = getDurationBySocialPlatform(platform);
  if (duration) {
    [durationMin, durationMax] = getRangeForDuration(duration);
  }

  const originalVideoWords = transcriptionText.split(' ').length;
  const newVideoWords = Math.round(
    (originalVideoWords * ((durationMin + durationMax) / 2)) / originalDuration,
  );
  const [clipLengthMin, clipLengthMax] = [durationMin, durationMax].map(
    (duration) =>
      Math.round((originalVideoWords * duration) / originalDuration),
  );

  // debugger;
  const clipProducerAiFlow = await loadAIFlow(getFlowNameByTheme(theme));
  // debugger;
  if (!clipProducerAiFlow) return [];
  clipProducerAiFlow.context = {
    storyTranscription: transcriptionText,
    storyLength: originalVideoWords,
    storyDuration: originalDuration,
    socialPlatform: platform,
    clipLengthMin,
    clipLengthMax,
    clipLength: newVideoWords,
    clipDurationMin: durationMin,
    clipDurationMax: durationMax,
    clipTheme: theme,
  };

  clipProducerAiFlow.steps[0].mapper = (
    flowContext: any,
    stepContext: AIFlowStep,
  ): (ClipFragment | null)[] => {
    // console.log('Mapper, lastResponse', stepContext.lastResponse);
    const resultParts =
      stepContext.lastResponse?.split('\n').filter((q) => q.trim()) || [];
    const NUMBER_OF_WORDS_TO_MATCH = 5;

    return resultParts.map((part: string, index: number) => {
      let beginning = stripPunctuationFromEnds(
        part.split(' ').slice(0, NUMBER_OF_WORDS_TO_MATCH).join(' '),
      );
      let ending = stripPunctuationFromEnds(
        part.split(' ').slice(-NUMBER_OF_WORDS_TO_MATCH).join(' '),
      );

      if (stepContext.doneStreaming) {
        stepContext.log(
          `Fragment ${index}: \nbeginning: ${beginning}, \nending: ${ending}`,
        );
      }

      const {
        startTime: beginningTime,
        transcriptPosition: beginningTranscriptPosition,
      } = getFragmentPosition(beginning, transcriptionElements) || {};
      if (beginningTime == null || !beginningTranscriptPosition) {
        if (stepContext.doneStreaming) {
          stepContext.log(
            `Fragment ${index} has no beginning position. Cannot find '${beginning}' in the transcription`,
          );
        }
        return null;
      }

      const {
        endTime: endingTime,
        transcriptPosition: endingTranscriptPosition,
      } = getFragmentPosition(ending, transcriptionElements) || {};

      if (endingTime == null || !endingTranscriptPosition) {
        if (stepContext.doneStreaming) {
          stepContext.log(
            `Fragment ${index} has no ending position. Cannot find '${ending}' in the transcription`,
          );
        }
        return null;
      }
      const fragmentDuration = endingTime - beginningTime;

      if (fragmentDuration >= durationMin && fragmentDuration <= durationMax) {
        if (stepContext.doneStreaming) {
          stepContext.log(
            `Fragment ${index} has a duration of ${fragmentDuration}s, which is within the allowed range`,
          );
        }
        return {
          index,
          text: part,
          duration: fragmentDuration,
          startTime: beginningTime,
          muxPlaybackId: originalVideo.video.muxPlaybackId,
          platform,
          theme,
          transcriptPosition: {
            startIndex: beginningTranscriptPosition.startIndex,
            endIndex: Math.min(
              endingTranscriptPosition.endIndex + 1,
              transcriptionElements.length - 1,
            ), // +1 to compensate for stripPunctuationFromEnds
          },
        };
      } else if (stepContext.doneStreaming) {
        stepContext.log(
          `Fragment ${index} has a duration of ${fragmentDuration}s, which is NOT within the allowed range: ${durationMin} - ${durationMax}`,
        );
      }
    }) as (ClipFragment | null)[];
    // .filter((part) => !!part)
  };

  clipProducerAiFlow.steps[0].onPartialResult = (
    result: (ClipFragment | null)[],
  ) => {
    if (abortToken.aborted) {
      throw new Error('Aborted');
    }
    if (result.length > 1) {
      onClipsProduced &&
        onClipsProduced(
          result.slice(0, -1).filter((part) => !!part) as ClipFragment[],
        );
    }
  };
  // debugger;
  await clipProducerAiFlow.run();

  if (abortToken.aborted) {
    throw new Error('Aborted');
  }

  const resultParts = clipProducerAiFlow.result.filter(
    (part: ClipFragment | null) => !!part,
  );

  return resultParts;

  // const fragmentsToRetry = [];
  // const fragmentsToUse = [];

  // for (let [index, part] of resultParts.entries()) {
  //   const {
  //     startTime,
  //     endTime,
  //     duration: fragmentDuration,
  //     transcriptPosition,
  //   } = getFragmentPosition(
  //     part,
  //     videoCreator.originalTranscription?.elements || [],
  //   ) || {};
  //   if (!startTime || !endTime || !fragmentDuration) continue;

  //   if (
  //     fragmentDuration < duration * 0.4 ||
  //     fragmentDuration > duration * 1.5
  //   ) {
  //     // fragmentsToRetry.push({
  //     //   index,
  //     //   text: part,
  //     //   duration: fragmentDuration,
  //     //   length: part.split(' ').length,
  //     // });
  //   } else {
  //     fragmentsToUse.push({
  //       index,
  //       text: part,
  //       duration: fragmentDuration,
  //       startTime,
  //       muxPlaybackId: originalVideo.video.muxPlaybackId,
  //       platform,
  //       theme,
  //       transcriptPosition,
  //     });
  //   }
  // }

  // return fragmentsToUse;

  // if (!response) return null;

  /*
  const fragmentsToRetry = [];
  const fragmentsToUse = [];

  for (let [index, part] of resultParts.entries()) {
    const {
      startTime,
      endTime,
      duration: fragmentDuration,
      transcriptPosition,
    } = getFragmentPosition(
      part,
      videoCreator.originalTranscription?.elements || [],
    ) || {};
    if (!startTime || !endTime || !fragmentDuration) continue;
    console.log(
      'startTime, duration from original:',
      startTime,
      fragmentDuration,
    );
    if (
      fragmentDuration < duration * 0.4 ||
      fragmentDuration > duration * 1.5
    ) {
      fragmentsToRetry.push({
        index,
        text: part,
        duration: fragmentDuration,
        length: part.split(' ').length,
      });
    } else {
      fragmentsToUse.push({
        index,
        text: part,
        duration: fragmentDuration,
        startTime,
        muxPlaybackId: originalVideo.video.muxPlaybackId,
        platform,
        theme,
        transcriptPosition,
      });
    }
  }

  let currentMessages: any[] = [
    ...allMessages,
    { role: 'assistant', content: response },
  ];

  debugger;

  for (let fragment of fragmentsToRetry) {
    const newMessages = [...currentMessages];
    if (fragment.duration < duration * 0.9) {
      const numberOfWordsToAdd =
        Math.max(0, newVideoWords - fragment.length) + 5;

      newMessages.push({
        role: 'user',
        content: `The part \n\n
        "${fragment.text}"
        \n\n
        in the result list has too few words. Can you extend the part by more ${
          numberOfWordsToAdd - 7
        } - ${numberOfWordsToAdd} words of the sorounding text from the same part of original story depending on what makes more sense. Even if the first or last sentences are not complete make to be ${newVideoWords} words long. Don't skip any word or sentence between this part and new words. Return only this specific part.`,
      });
    } else {
      const numberOfWordsToRemove =
        Math.max(0, fragment.length - newVideoWords) + 5;

      newMessages.push({
        role: 'user',
        content: `The part \n\n
        "${fragment.text}"
        \n\n in the result list has too many words. Can you remove ${
          numberOfWordsToRemove - 7
        } - ${numberOfWordsToRemove} words from the beginning or the end of this part depending on what makes more sense even if some of the sentences are not complete. Return only this specific part`,
      });
    }
    console.log('TRYING AGAIN with new request', newMessages);
    const completion = await openai.chat.completions.create({
      // @ts-ignore
      messages: newMessages,
      model: defaultModel,
      temperature: 0.6,
    });

    const regeneratedPart = completion.choices[0].message.content;
    console.log('ChatGpt result 2', regeneratedPart);
    if (!regeneratedPart) continue;
    debugger;
    const {
      startTime,
      endTime,
      duration: fragmentDuration,
      transcriptPosition,
    } = getFragmentPosition(
      regeneratedPart,
      videoCreator.originalTranscription?.elements || [],
    ) || {};
    if (!startTime || !endTime || !fragmentDuration) continue;
    debugger;
    console.log(
      'startTime, duration from original:',
      startTime,
      fragmentDuration,
    );
    if (
      fragmentDuration > duration * 0.85 &&
      fragmentDuration < duration * 1.15
    ) {
      fragmentsToUse.push({
        index: fragment.index,
        text: regeneratedPart,
        duration: fragmentDuration,
        startTime,
        muxPlaybackId: originalVideo.video.muxPlaybackId,
        platform,
        theme,
        transcriptPosition,
      });
    }
    // const {
    //   startTime,
    //   endTime,
    //   duration: fragmentDuration,
    //   transcriptPosition,
    // } = getFragmentPosition(
    //   response,
    //   videoCreator.originalTranscription?.elements || [],
    // ) || {};
  }
  debugger;
  //     if (response) {
  //       resultWords = response.split(' ').length;
  //     }
  //     tries += 1;
  //   }
  // }
  // return response;
  return fragmentsToUse;*/
};

function getFragmentPosition(
  fragment: string,
  transcriptElements?: TranscriptElement[],
): {
  transcriptPosition: {
    startIndex: number;
    endIndex: number;
  };
  duration: number;
  startTime: number;
  endTime: number;
} | null {
  let targetSentence = fragment;
  // loop through all transcript elements and track the index of the target sentence across multiple elements
  if (!transcriptElements || !targetSentence) return null;

  let targetSentenceCurrIndex = 0;
  let currElementIndex = 0;
  let targetSentenceElementStartIndex = 0;
  let targetSentenceElementEndIndex = 0;
  let found = false;

  while (currElementIndex < (transcriptElements?.length - 1 || 0)) {
    let currElementValue = transcriptElements[currElementIndex].value;

    // if element contains the start of the target sentence save it as the start of the index
    if (
      currElementValue &&
      transcriptElements[currElementIndex]?.state !== 'removed' &&
      transcriptElements[currElementIndex]?.state !== 'cut'
    ) {
      if (
        targetSentence.substring(
          targetSentenceCurrIndex,
          targetSentenceCurrIndex + currElementValue.length,
        ) === currElementValue
      ) {
        if (targetSentenceCurrIndex === 0) {
          targetSentenceElementStartIndex = currElementIndex;
        }
        targetSentenceCurrIndex += currElementValue.length;
      } else {
        targetSentenceCurrIndex = 0;
      }
    }
    if (targetSentenceCurrIndex === targetSentence.length) {
      targetSentenceElementEndIndex = currElementIndex;
      found = true;
      break;
    }
    currElementIndex++;
  }

  // RETURN if not found
  if (!found) return null;

  let ts = null;
  let end_ts = null;

  // loop through all transcript elements and track the index of the target sentence across multiple elements
  currElementIndex = targetSentenceElementStartIndex;
  while (currElementIndex <= targetSentenceElementEndIndex) {
    const currElementFinal = transcriptElements[currElementIndex];

    if (currElementFinal) {
      if (ts === null && currElementFinal.ts != null) {
        ts = currElementFinal.ts - (currElementFinal.buffer_before_ts || 0);
      }
      if (currElementFinal.end_ts) {
        end_ts =
          currElementFinal.end_ts + (currElementFinal.buffer_after_ts || 0);
      }
    }
    currElementIndex++;
  }

  if (ts == null || end_ts == null) return null;

  return {
    transcriptPosition: {
      startIndex: targetSentenceElementStartIndex,
      endIndex: targetSentenceElementEndIndex,
    },
    duration: end_ts - ts,
    startTime: ts,
    endTime: end_ts,
  };
}

function stripPunctuationFromEnds(text: string) {
  return text.replaceAll(/^[\W]+|[\W]+$/g, '');
}

export async function saveProducedClips(
  clips: ClipFragment[],
  sourcePlatform: 'creator-studio' | 'content-studio',
): Promise<void> {
  let originalSource = videoCreator.renderer?.getSource();
  const originalVideo = toJS(videoCreator.currentVideo!);
  if (!originalSource) {
    originalSource = videoCreator.currentVideo?.videoSource;
  }
  if (!originalSource) {
    throw new Error('No video source found');
  }

  const originalDuration = videoCreator.duration;
  if (sourcePlatform === 'creator-studio') {
    // same source should be used for all produced clips
    for (const clip of clips) {
      await saveClipInCreator(
        clip,
        originalVideo,
        originalSource,
        originalDuration,
      );
    }
  } else {
    for (const clip of clips) {
      await videoCreator.createNewVideoForContentClip(
        originalVideo,
        sourcePlatform,
        clip,
      );

      copyClipToCurrentVideo(clip);
      await videoCreator.saveStoryAndVideo(false, false);
    }
  }
}

export async function saveClipFromTranscript(clip: any) {
  const originalDuration = videoCreator.duration;
  await videoCreator.saveStoryAndVideo();
  const originalVideo = videoCreator.currentVideo;
  const originalSource = videoCreator.renderer?.getSource();
  if (!originalSource || !originalVideo) {
    throw new Error('No video source found to create clip');
  }
  await saveClipInCreator(
    clip,
    originalVideo,
    originalSource,
    originalDuration,
  );
}

export async function saveClipInCreator(
  clip: any,
  originalVideo: Video,
  originalSource: any,
  originalDuration: number,
) {
  const newTitle = `${originalVideo.title} - ${clip.theme}`;
  await videoCreator.createNewVideoFromSource(
    newTitle,
    originalSource,
    originalVideo.transcriptionChanges,
    originalVideo.transcriptionSnapshot,
    originalVideo.extraElementData,
    'creator-studio',
  );

  await videoCreator.cropTranscriptAndVideoTo(
    clip.transcriptPosition.startIndex,
    clip.transcriptPosition.endIndex,
    originalDuration,
  );

  await videoCreator.setDuration(null);
  copyClipToCurrentVideo(clip);
  await videoCreator.saveStoryAndVideo();
}

function copyClipToCurrentVideo(clip: ClipFragment) {
  videoCreator.currentVideo!.clipJson = {
    startTime: clip.startTime,
    duration: clip.duration,
    muxPlaybackId: clip.muxPlaybackId,
  };
}
